const mp = new MercadoPago('APP_USR-c066b27c-c2eb-4c52-b327-7a2863eaddad', {
    locale: 'es-AR'
});

document.addEventListener("DOMContentLoaded", () => {
    console.log("DOM completamente cargado");
    obtenerDatosEnvio();
});

function obtenerDatosEnvio() {
    const gastosEnvioElemento = document.querySelector("#gastoEntrega span");
    const direccionElemento = document.querySelector("#direccionSeleccionada span");

    const gastosEnvio = gastosEnvioElemento ? parseFloat(gastosEnvioElemento.textContent.replace(/[^\d,]/g, '').replace(',', '.')) : 0;
    const direccionSeleccionada = direccionElemento;
}

const observer = new MutationObserver(() => {
    obtenerDatosEnvio();
});
observer.observe(document.body, { childList: true, subtree: true });

async function handleMercadoPago() {
    console.log("Botón presionado");

    try {
        const nombreElemento = document.querySelector("[data-nombre]");
        const cantidadElemento = document.querySelector("[data-cantidad]");
        const montoElemento = document.querySelector(".total-span");
        
        if (!nombreElemento || !cantidadElemento || !montoElemento) {
            throw new Error("Faltan elementos necesarios en el DOM (nombre, cantidad o monto).");
        }

        const totalTexto = montoElemento.textContent.replace(/\s+/g, '');
        const totalNumerico = parseFloat(totalTexto.replace(',', '.'));

        if (isNaN(totalNumerico)) {
            throw new Error("El valor total extraído no es un número válido.");
        }

        const cantidad = parseInt(cantidadElemento.getAttribute("data-cantidad"));
        if (isNaN(cantidad) || cantidad <= 0) {
            throw new Error("La cantidad no es válida o es menor o igual a cero.");
        }

        const montoUnitario = totalNumerico / cantidad;

        const gastosEnvioElemento = document.querySelector("#gastoEntrega span");

        const gastosEnvio = gastosEnvioElemento ? parseFloat(gastosEnvioElemento.textContent.replace(/[^\d,]/g, '').replace(',', '.')) : 0;
        const direccionSeleccionada = localStorage.getItem('ubicacion');
        const tipoEnvio = localStorage.getItem('tipoEnvio');

        const userBuyer = {
            nombreElemento: nombreElemento.getAttribute("data-nombre"),
            cantidadElemento: cantidad,
            montoElemento: montoUnitario,
            gastosEntrega: gastosEnvio,
            direccion: direccionSeleccionada,
            tipoEnvio: tipoEnvio
        };

        if (!userBuyer.nombreElemento || isNaN(userBuyer.cantidadElemento) || isNaN(userBuyer.montoElemento)) {
            throw new Error("Datos inválidos en userBuyer.");
        }

        console.log(JSON.stringify(userBuyer))
        const response = await fetch("https://t-papeleria-back.quinto.site/api/mp", {
            method: 'POST',
            headers: {
                'Accept': 'Application/json',
                'Content-Type': 'Application/json',
            },
            body: JSON.stringify(userBuyer)
        });

        const preference = await response.text();
        console.log("preference: " + preference);

        createCheckoutButton(preference);

    } catch (error) {
        console.error("Error en handleMercadoPago:", error);
    }
}

function createCheckoutButton(preferenceId) {
    const bricksBuilder = mp.bricks();

    const renderComponent = async () => {
        if (window.checkoutButton) {
            window.checkoutButton.unmount();
        }

        await bricksBuilder.create("wallet", "wallet_container", {
            initialization: {
                preferenceId: preferenceId,
            },
            customization: {
                texts: {
                    valueProp: 'Pagar con Mercado Pago',
                },
            },
            locale: 'es-AR',
        });
    };

    renderComponent();
}
